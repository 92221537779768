import React, { useEffect } from 'react'
import { getId } from '../services/idService'
var JsBarcode = require('jsbarcode')

export const Barcode = ({ setDisplayError }) => {
    useEffect(() => {
        createBarcode()
    })
    
    const createBarcode = () => {
        let id = getId()
        if (id === false) {
            setDisplayError(true)
            return
        }
        JsBarcode("#barcode", id, {
            width: 2,
            height: 100
        })
    }

    return (
        <div>
            <canvas id="barcode"></canvas>
        </div>
    )
}

export default Barcode;