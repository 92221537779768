import React from 'react'

export default function Footer({ showFooter, link, text }) {
    return (
        <div className="footer">
            { showFooter && <a className="link" href={link}>{text}</a> }
            <div className="break"></div>
            <div className="tegra">
                <p className="tegraText">Powered by</p>
                <div className="break"></div>
                <img className="tegraLogo" src="/Tegra-logo-sRGB-fjolublatt.png" alt="Tegra logo"/>
            </div>
        </div>
    )
}
