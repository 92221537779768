import './App.css';
import Item from './components/Item'

function App() {
  const { 
    LOGO, 
    LOGO_LINK, 
    MESSAGE, 
    SUB_MESSAGE,
    BARCODE, 
    QRCODE, 
    FOOTER, 
    FOOTER_LINK, 
    FOOTER_TEXT } = window['config']
  return (
    <div className="App">
      <Item 
        logo={LOGO}
        logoLink={LOGO_LINK}
        message={MESSAGE}
        subMessage={SUB_MESSAGE}
        barcode={BARCODE} 
        qrcode={QRCODE} 
        footer={FOOTER} 
        footerText={FOOTER_TEXT}
        footerLink={FOOTER_LINK} />
    </div>
  );
}

export default App;
