import React from 'react'

export default function Message({ text, subMessage }) {
    if(subMessage) {
        return (
            <div className="subMessage">
                {text}
            </div>
        )
    } else  {
        return (
            <div className="message">
                {text}
            </div>
        )
    }
}
