import PropTypes from 'prop-types'
import React from 'react'
import Logo from './Logo'
import Message from './Message'
import Barcode from './Barcode'
import Footer from './Footer'
import NotFound from './NotFound'
import QRCode from './QRCode'
import { useState } from 'react';

function Item({ logo, logoLink, message, subMessage, barcode, qrcode, footer, footerLink, footerText }) {
    const [displayError, setDisplayError] = useState(false)
    return (
        <>
            { displayError ?
                <NotFound />
            :
                <>
                <div className="header">
                    { logo && <Logo logoLink={logoLink} /> }
                    <Message text={message}/>
                    <Message text={subMessage} subMessage={true}/>
                </div>
                <div className="item">
                    { barcode && <><Barcode setDisplayError={setDisplayError} /><div className="break"></div></> }
                    { qrcode && <><QRCode setDisplayError={setDisplayError} /><div className="break"></div></> }
                    <Footer showFooter={footer} text={footerText} link={footerLink} /><div className="break"></div>
                </div>
                </>
            }
            
        </>
    )
}

Item.propTypes = {
    logo: PropTypes.bool,
    logoLink: PropTypes.string,
    message: PropTypes.string,
    subMessage: PropTypes.string,
    barcode: PropTypes.bool,
    qrcode: PropTypes.bool,
    footer: PropTypes.bool,
    footerText: PropTypes.string,
    footerLink: PropTypes.string
}

Item.defaultProps = {
    logo: false,
    logoLink: "",
    message: "",
    subMessage: "",
    barcode: true,
    qrcode: false,
    footer: false,
    footerLink: "",
    footerText: ""
}

export default Item
