import React, { useEffect } from 'react'
import { getId } from '../services/idService'
var QRCodeLib = require('qrcode')

export const QRCode = ({ setDisplayError }) => {
    useEffect(() => {
        createQRCode()
    })

    const createQRCode = () => {
        let id = getId()
        if (id === false) {
            setDisplayError(true)
            return
        }
        let QRCanvas = document.getElementById('qrcode')
        QRCodeLib.toCanvas(QRCanvas, id, {
            width: 300,
        })
    }

    return (
        <div>
            <canvas id="qrcode"></canvas>
        </div>
    )
}

export default QRCode;