export const getId = () => {
    // Get the url parameter from the key 'b'
    let url = new URL(window.location.href)
    let id = url.searchParams.get("b")
    return decode(id)
}

const decode = (id) => {
    // Decode id from Base64 
    try {
        let barcode = atob(id)
        return barcode
    }
    catch(err) {
        return false
    }
}
